import { ReactComponent as Album } from './album.svg';
import { ReactComponent as ArrowForward } from './arrow_forward.svg';
import { ReactComponent as Cached } from './cached.svg';
import { ReactComponent as CheckBox } from './check_box.svg';
import { ReactComponent as CheckBoxOutlineBlank } from './check_box_outline_blank.svg';
import { ReactComponent as ChevronLeft } from './chevron_left.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Computer } from './computer.svg';
import { ReactComponent as Moon } from './moon.svg';
import { ReactComponent as Earth } from './earth.svg';
import { ReactComponent as ErrorOutline } from './error_outline.svg';
import { ReactComponent as ExpandMore } from './expand_more.svg';
import { ReactComponent as Explore } from './explore.svg';
import { ReactComponent as Sun } from './sun.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as MoreVert } from './more_vert.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as PeopleAlt } from './people_alt.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as PlayArrow } from './play_arrow.svg';
import { ReactComponent as QueueMusic } from './queue_music.svg';
import { ReactComponent as Repeat } from './repeat.svg';
import { ReactComponent as RepeatOne } from './repeat_one.svg';
import { ReactComponent as Schedule } from './schedule.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Shuffle } from './shuffle.svg';
import { ReactComponent as SkipNext } from './skip_next.svg';
import { ReactComponent as SkipPrevious } from './skip_previous.svg';
import { ReactComponent as VolumeDown } from './volume_down.svg';
import { ReactComponent as VolumeOff } from './volume_off.svg';
import { ReactComponent as VolumeUp } from './volume_up.svg';

export const AlbumIcon = Album;
export const ArrowForwardIcon = ArrowForward;
export const ReloadIcon = Cached;
export const CheckBoxUncheckedIcon = CheckBoxOutlineBlank;
export const CheckBoxCheckedIcon = CheckBox;
export const ChevronLeftIcon = ChevronLeft;
export const CloseIcon = Close;
export const ComputerIcon = Computer;
export const MoonIcon = Moon;
export const EarthIcon = Earth;
export const ExclamationMarkIcon = ErrorOutline;
export const ExpandMoreIcon = ExpandMore;
export const ExploreIcon = Explore;
export const SunIcon = Sun;
export const MenuIcon = Menu;
export const OptionsIcon = MoreVert;
export const PauseIcon = Pause;
export const ArtistsIcon = PeopleAlt;
export const UserIcon = Person;
export const PlayIcon = PlayArrow;
export const QueueIcon = QueueMusic;
export const RepeatOneIcon = RepeatOne;
export const RepeatIcon = Repeat;
export const DurationIcon = Schedule;
export const SearchIcon = Search;
export const SettingsIcon = Settings;
export const ShuffleIcon = Shuffle;
export const SkipNextIcon = SkipNext;
export const SkipPreviousIcon = SkipPrevious;
export const VolumeDownIcon = VolumeDown;
export const VolumeOffIcon = VolumeOff;
export const VolumeUpIcon = VolumeUp;
